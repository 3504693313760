<template>
  <div class="card">
    <div class="card-body px-lg-5 py-lg-5 signup-profile">
      <h5 class="card-title text-center">CREATE YOUR ACCOUNT</h5>
      <div v-if="$store.getters.login_message">
        <base-alert
          v-if="$store.getters.login_message != 'Account successfully verified'"
          type="info"
          dismissible
        >
          {{ $store.getters.login_message }}</base-alert
        >
      </div>
      <div v-if="account_created">
        <base-alert type="success">Account created</base-alert>
        <p>
          <strong>{{ account_created }}</strong>
          <br />
          Please click the link in that email to continue.
        </p>
        <p class="text-center">
          <base-button
            @click="$router.push('/login')"
            size="lg"
            block
            type="primary"
            outline
            class="ntt"
            >Log In</base-button
          >
        </p>
      </div>
      <validation-observer v-else ref="form">
        <form role="form" @submit.prevent="signup">
          <div class="form-group mb-3">
            <!-- <h5>User Registration</h5> -->
            <validation-provider
              name="Full Name"
              rules="required|min:4"
              v-slot="{ errors }"
            >
              <base-input
                name="name"
                v-model="user.full_name"
                placeholder="Full Name"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>

          <div class="form-group mb-3">
            <validation-provider
              mode="aggressive"
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <base-input
                name="email"
                v-model="user.email"
                placeholder="Email"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>
          <div class="form-group mb-3">
            <validation-provider
              mode="aggressive"
              name="ConfirmEmail"
              rules="required|email|confirm_email:@Email"
              v-slot="{ errors }"
            >
              <base-input
                v-model="user.confirm_email"
                placeholder="Confirm Email"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>
          <div class="form-group">
            <validation-provider
              mode="aggressive"
              name="Password"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <base-input
                name="password"
                v-model="user.password"
                type="password"
                placeholder="Password"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>
          <div class="form-group">
            <validation-provider
              mode="aggressive"
              name="ConfirmPassword"
              rules="required|min:6|password:@Password"
              v-slot="{ errors }"
            >
              <base-input
                v-model="user.confirm_password"
                type="password"
                placeholder="Confirm Password"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>

          <div class="form-group">
            <!-- <h5>User Profile</h5> -->
            <label for="profession">Profession</label>
            <validation-provider
              name="Profession"
              rules="required"
              v-slot="{ errors }"
            >
              <choices-single
                v-if="!loadings.profession"
                id="profession"
                :options="options.profession"
                v-model="user.profession"
              >
              </choices-single>
              <small v-if="errors[0]" class="text-danger"
                >This field is required</small
              >
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="marketing-source">How did you find us?</label>
            <validation-provider
              name="MarketingSource"
              rules=""
              v-slot="{ errors }"
            >
              <choices-single
                v-if="!loadings.marketing_source"
                id="marketing-source"
                :options="options.marketing_source"
                v-model="user.marketing_source"
              >
              </choices-single>
              <small v-if="errors[0]" class="text-danger"
                >This field is required</small
              >
            </validation-provider>
          </div>

          <div class="form-group">
            <div>
              <label for="reason">Reason for searching</label>
            </div>
            <validation-provider
              name="Reason for searching"
              rules=""
              v-slot="{ errors }"
            >
              <textarea
                v-model="user.reason"
                id="reason"
                class="form-control"
                rows="3"
                spellcheck="false"
                placeholder="Reason for searching"
              ></textarea>
              <small v-if="errors[0]" class="text-danger">
                This field is required</small
              >
            </validation-provider>
          </div>
          <div class="custom-control-alternative custom-checkbox pl-0">
            <router-link
              to="/terms-and-conditions"
              style="position:relative;z-index:2;top:2px"
              class="float-right"
              >More details</router-link
            >
            <validation-provider
              name="TermsAgreed"
              rules="terms"
              v-slot="{ errors }"
            >
              <base-checkbox v-model="user.terms_agreed" alternative
                >Terms of Service</base-checkbox
              >
              <small v-if="errors[0]" class="text-danger"
                >You must agree to our terms</small
              >
            </validation-provider>
          </div>
          <div>
            <base-alert v-if="errors.email" type="danger"
              >A user with that email address already exist.</base-alert
            >
            <base-alert v-if="errors.other" type="danger"
              >An unexpected error has occurred</base-alert
            >
          </div>
          <GoogleRecaptcha ref="recaptcha" style="margin-top: 10px;" />
          <div class="text-center">
            <base-button
              :disabled="loadings.button"
              block
              @click="signup()"
              type="login"
              class="mt-3 ntt"
            >
              <spinner
                v-if="loadings.button"
                type="grow"
                class="spinner-grow-sm"
              ></spinner>
              <span v-else>Sign Up</span>
            </base-button>
          </div>
          <input type="submit" style="display: none" />
          <div class="text-center mt-3 text-dark">
            If you already have an account
            <router-link class="mylink" to="/login">
              Log In here
            </router-link>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import GoogleRecaptcha from "@/components/GoogleRecaptcha";
const ChoicesSingle = () =>
  import(/* webpackChunkName: "ChoicesSingle"*/ "@/components/SingleSelect");

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  bodyClass: "login-page",
  components: {
    Spinner,
    GoogleRecaptcha,
    ChoicesSingle,
    ValidationProvider,
    ValidationObserver,
  },
  data: function() {
    return {
      user: { terms_agreed: false },
      remember_me: false,
      loadings: {
        button: false,
        profession: true,
        marketing_source: true,
      },
      options: { profession: [], marketing_source: [] },
      errors: {
        email: false,
        other: false,
      },
      account_created: false,
    };
  },
  beforeMount() {
    this.updateOptions();
  },
  methods: {
    updateOptions() {
      this.$axios.get("profession/").then((res) => {
        this.options.profession = res.data;
        this.loadings.profession = false;
      });
      this.$axios.get("marketing-source/").then((res) => {
        this.options.marketing_source = res.data;
        this.loadings.marketing_source = false;
      });
    },
    signup() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.errors.email = false;
        this.errors.other = false;
        this.loadings.button = true;

        let recaptchaResponse = "";
        try {
          recaptchaResponse = grecaptcha.getResponse(0);
        } catch (error) {
          console.error(error);
          alert("Recaptcha processing error");
          this.loadings.button = false;
          return;
        }

        if (!recaptchaResponse) {
          alert("Please complete the reCAPTCHA");
          this.loadings.button = false;
          return;
        }

        this.$axios
          .post("verify-recaptcha/", { captcha_token: recaptchaResponse })
          .then((response) => {
            this.loadings.button = false;

            if (response.status === 200 && response.data.success === true) {
              this.loadings.button = true;
              this.$axios
                .post("signup/", this.user)
                .then((res) => {
                  if (res.status == 200) {
                    this.account_created = res.data.detail;
                  } else {
                    this.loadings.button = false;
                  }
                })
                .catch((err) => {
                  if (err.response.data.email[0]) {
                    this.errors.email = true;
                  } else {
                    this.errors.other = true;
                  }
                  this.loadings.button = false;
                });
            } else {
              alert("Recaptcha verification failed");
              this.loadings.button = false;
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Recaptcha verification unavailable");
            this.loadings.button = false;
          });
      });
    },
  },
};
</script>
<style></style>

<template>
  <div
    v-if="captchaReady"
    id="recaptcha-container"
    class="g-recaptcha"
    :data-sitekey="sitekey"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      captchaReady: false,
      sitekey: "6Lf4JUkqAAAAAJOOIFmIgdr1bMIP2jpdz-ZrPvKT",
    };
  },
  mounted() {
    this.loadReCaptcha();
  },
  methods: {
    loadReCaptcha() {
      if (typeof grecaptcha === "undefined") {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
        script.onload = this.initReCaptcha;
        document.head.appendChild(script);
      } else {
        this.initReCaptcha();
      }
    },
    initReCaptcha() {
      this.captchaReady = true;
      grecaptcha.ready(() => {
        grecaptcha.render("recaptcha-container", {
          sitekey: this.sitekey,
        });
      });
    },
  },
};
</script>
